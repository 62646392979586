@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

h1,
h2,
h3,
h4,
.accent {
  overflow: hidden;
  font-weight: var(--fw-700);
  text-transform: capitalize;
}

h2,
h3,
.accent {
  color: var(--color-orange-500);
}

h1 {
  font-size: clamp(32px, 4vw, 62px);
  line-height: 1.2;
  color: var(--color-white);
  letter-spacing: 0.5px;
}

h2,
.title {
  margin-bottom: 24px;
  line-height: 1.1;
  text-align: center;
}

h2 {
  font-size: clamp(26px, 3.5vw, 56px);
}

h3,
.accent {
  margin-bottom: 16px;
  font-size: clamp(22px, .5rem + 1.5vi, 32px);
  line-height: 1;
  letter-spacing: 0.5px;
}

.accent {
  margin-bottom: 24px;
}

h4 {
  font-size: clamp(20px, 2vw, 32px);
  line-height: 1.3;
  letter-spacing: 0.5px;
  color: var(--color-orange-500);
}

.title {
  font-size: clamp(30px, 3vw, 48px);
  color: var(--color-purple-600);
  letter-spacing: 1.2px;
}

.desc {
  font-size: clamp(22px, 1rem + 1vi, 32px);
  line-height: 1.4;
  font-weight: var(--fw-500);
  color: var(--color-white);
  letter-spacing: 1.2px;
}

a {
  font-size: clamp(18px, 1rem + 1vi, 20px);
  line-height: 1;
  font-weight: var(--fw-700);
  color: var(--color-white);
}

p {
  line-height: 1.4;
  letter-spacing: .7px;
}

.intro {
  max-width: 636px;
  width: 100%;
  margin: 0 auto rem(32);
  text-align: center;
}

textarea {
  color: var(--color-purple-600);

  &::placeholder {
    color: var(--color-purple-670);
  }
}

.copyright {
  font-size: 16px;
  line-height: 1.33vw;
  color: var(--color-white);
}