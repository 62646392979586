@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.footer {
  padding-block: rem(51);
  background-color: var(--color-purple-600);

  &__content,
  &__form {
    display: flex;
    flex-direction: column;
  }

  &__content {
    justify-content: center;
    align-items: center;
  }

  .logo,
  &__mail {
    margin-bottom: 24px;
  }

  &__mail {
    display: flex;
    align-items: center;

    a, span {
      font-size: clamp(20px, 1.5vw, 24px);
      line-height: 1.5;
      font-weight: var(--fw-500);
      color: var(--color-white);
      letter-spacing: 1.4px;
    }
  }

  &__form {
    gap: 16px;
    max-width: 636px;
    width: 100%;
    margin-bottom: rem(45);

    textarea,
    .btn {
      border-radius: var(--radius-main);
      width: 100%;
    }

    textarea {
      min-height: 240px;
      padding: 16px;
      resize: none;
    }
  }

  &__links {
    display: none;
    align-items: center;
    margin-bottom: 24px;
  }

  &__item {
    &:not(:last-of-type) {
      margin-right: 16px;
    }
  }

  @include media(tablet) {
    padding: rem(30) 0;

    &__form {
      margin-bottom: 40px;
    }

    &__links {
      display: flex;
    }
  }

  @include media(mobile-s) {
    &__mail {
      flex-direction: column;
    }

    &__link {
      font-size: 18px;
      font-weight: var(--fw-500);
    }
  }
}