@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.nav {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    height: auto;
  }

  .logo {
    max-width: 127px;
    width: 100%;
    margin-right: 24px;

    @include media(tablet) {
      max-width: 100px;
    }
  }

  &__inner {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: clamp(20px, 10vw, 150px);
    width: 100%;

    @include media(tablet-wide) {
      gap: 24px;
    }

    @include media(tablet) {
      position: fixed;
      left: 0;
      top: calc(var(--header-height) * 1);
      z-index: -1;
      flex-direction: column;
      justify-content: flex-start;
      overflow-y: auto;
      padding: 30px 16px;
      width: 100%;
      height: calc(var(--vh));
      background-color: var(--color-purple-600);
      transform: translateX(150%) translateY(-2px);
      transition-property: transform;
      transition-duration: 0.4s;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    max-width: 529px;
    width: 100%;

    @include media(tablet) {
      z-index: -1;
      flex-direction: column;
      margin-right: 0;
    }
  }

  &__item {
    &:not(:last-of-type) {
      margin-right: clamp(24px, 3.5vw, 50px);

      @include media(tablet) {
        margin-right: 0;
      }
    }

    @include media(tablet) {
      margin-bottom: 30px;
    }
  }

  &__link {
    text-transform: capitalize;
    transition: color 0.4s ease-in-out;

    &.true,
    &:hover {
      color: var(--color-orange-500);
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    gap: 34px;
    max-width: 315px;
    width: 100%;

    @include media(tablet) {
      flex-direction: column;
      gap: 30px;
    }
  }

  &--footer {
    margin-bottom: rem(45);

    .nav__list {
      max-width: 100%;
      justify-content: center;
    }

    .nav__item {
      padding-block: 18px;
    }

    @include media(tablet) {
      display: none;
    }
  }
}