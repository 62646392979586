@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.hero {
  $root: &;
  padding: 0;

  &__inner {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: rem(141) 0;
    min-height: 488px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__content {
    position: relative;
    z-index: 2;
    max-width: 856px;
    width: 100%;
  }

  h1 {
    margin-bottom: 16px;
  }

  &--home {
    .hero__inner {
      min-height: 732px;
      padding: rem(61) 0;
    }

    .desc {
      width: 90%;
      margin-bottom: rem(32);
    }
  }

  &--services {
    .hero__inner {
      padding-block: rem(64)
    }
  }

  @include media(tablet) {
    &__inner {
      padding: 60px 0;
      min-height: 350px;
    }

    &--home {
      .hero__inner {
        min-height: 500px;
        padding: 50px 0;
      }
    }

    &--services {
      .hero__content {
        width: 80%;
      }
    }
  }

  @include media(mobile-m) {
    &--single {
      .hero__inner {
        min-height: 175px;
      }
    }
  }

  @include media(mobile-s) {
    &--services {
      .hero__content {
        width: 100%;
      }
    }
  }
}