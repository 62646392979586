@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.header {
  position: fixed;
  top: 0;
  z-index: 10;
  padding: clamp(12px, 2vi, 22.5px) 0;
  width: 100%;
  background-color: var(--color-purple-600);
  transition-property: transform, padding;
  transition-duration: 0.15s;

  &.hide {
    transform: translateY(-100%);
  }
}