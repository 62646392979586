@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

*[class].btn {
  display: inline-block;
  margin: 0;
  padding: 18px 26px;
  font-family: var(--font-family-primary);
  font-weight: var(--fw-700);
  font-size: clamp(18px, 1rem + 1vi, 20px);
  border: none;
  line-height: 1;
  color: var(--color-purple-600) !important;
  text-align: center;
  border-radius: var(--radius-main);
  background-color: var(--color-white);
  transition-property: background-color, color;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;

  &--reverse,
  &:hover {
    color: var(--color-white) !important;
    background-color: var(--color-orange-500);
  }

  &--reverse {
    &:hover {
      background-color: var(--color-white);
      color: var(--color-purple-600) !important;
    }
  }
}