@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.choose {
  padding-bottom: rem(80);

  .intro {
    line-height: 1.36;
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: dense;
    align-items: stretch;
    gap: 24px;
  }

  &__element {
    position: relative;
    display: flex;
    align-items: flex-end;
    min-height: 300px;
    height: 100%;
    padding: 20px;
    border-radius: var(--radius-medium);
    overflow: hidden;

    &:last-of-type {
      grid-column: span 2;
      min-height: 307px;

      .choose__content {
        width: 49%;
      }
    }
  }

  &__img {
    position: absolute;
    inset: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
  }

  &__content {
    width: 70%;
    padding: 24px;
    background-color: var(--color-gray-100);
    border-radius: var(--radius-medium);
  }

  &--vertical {
    h2 {
      margin-bottom: rem(32);
    }

    .choose__items {
      margin-bottom: rem(32);
    }

    .choose__item {
      &--odd,
      &--even {
        grid-row: span 2;

        h3 {
          font-size: clamp(20px, 2vw, 32px);
        }
      }

      &--even {
        grid-column: 2;
      }

      &--left {
        grid-column: 1;
      }
    }

    .choose__element {
      min-height: 268px;
      border-radius: var(--radius-large);
      padding-bottom: 16px;
      padding-left: 32px;
    }

    .choose__link {
      width: 75%;

      &::before {
        content: "";
        position: absolute;
        inset: 0;
        z-index: 2;
      }
    }

    h3 {
      font-size: clamp(20px, 1.5vw, 24px);
      line-height: 1.4;
      color: var(--color-white);
    }

    .btn {
      display: block;
      width: fit-content;
      margin-inline: auto;
    }
  }

  &--insights {
    padding-bottom: rem(50);
    h2 {
      margin-bottom: rem(60);
    }
  }

  @include media(tablet) {
    padding-bottom: rem(30);

    &__items {
      gap: 16px;
    }

    &__element {
      padding: 16px;

      &:last-of-type {
        .choose__content {
          width: 65%;
        }
      }
    }

    &__content {
      width: 85%;
      padding: 16px;
    }

    &__img {
      object-position: right;
    }

    &--vertical {
      .choose__element {
        min-height: 200px;
        padding-left: 16px;
      }

      .choose__link {
        width: 90%;
      }
    }

    &--insights {
      h2 {
        margin-bottom: 24px;
      }

      .choose__items {
        margin-bottom: 0;
      }
    }
  }

  @include media(mobile-l) {
    &__items {
      grid-template-columns: 1fr;
    }

    &__item {
      &:last-of-type {
        grid-column: auto;
      }
    }

    &__element {
      min-height: 350px;

      &:last-of-type {
        min-height: 350px;

        .choose__content {
          width: 90%;
        }
      }
    }

    &__content {
      width: 90%;
    }
  }
}