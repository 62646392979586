@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

.policy {
  max-width: 746px;
  width: 100%;

  h1 {
    margin-bottom: 24px;
    color: var(--color-orange-500);
  }

  h2 {
    color: var(--color-purple-600);
    font-size: clamp(20px, .5rem + 1.5vi, 32px);
    text-align: left;
  }

  & > *:not(:last-child),
  .policy__content:not(:last-child),
  .policy__content > *:not(:last-child) {
    margin-bottom: 32px;
  }

  ul {
    padding-left: 32px;

    @include media(tablet) {
      padding-left: 16px;
    }
  }

  li {
    list-style: disc;
  }

  a {
    color: var(--color-orange-500);
    text-decoration: underline;
  }

  @include media(desktop) {
    max-width: 100%;
  }
}