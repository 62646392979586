@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.services {
  padding: rem(64) 0 rem(112);

  h2 {
    margin-bottom: rem(64);
  }

  img {
    margin-bottom: rem(32);
    border-radius: var(--radius-medium);
  }

  .intro {
    margin-bottom: 32px;
    max-width: 746px;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    align-items: stretch;
    gap: 24px;
    margin-bottom: rem(32);
  }

  &__item {
    padding: 32px;
    background-color: var(--color-gray-100);
    border-radius: var(--radius-medium);

    h4 {
      margin-bottom: 8px;
    }
  }

  .btn {
    display: block;
    width: fit-content;
    margin-inline: auto;
  }

  &--cyber {
    padding: 0 0 rem(112);
  }

  &--cloud {
    padding: 0 0 rem(80);
  }

  @include media(tablet) {
    padding: rem(30) 0;

    h2,
    img,
    .intro {
      margin-bottom: 24px;
    }

    &__grid {
      gap: 16px;
    }

    &__item {
      padding: 16px;
    }
  }

  @include media(mobile-l) {
    &__grid {
      grid-template-columns: 1fr;
      grid-auto-rows: auto;
    }
  }

  @include media(mobile-m) {
    img {
      height: auto;
      aspect-ratio: 4 / 3;
    }
  }
}