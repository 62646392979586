@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.box {
  &__flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__content {
    max-width: 634px;
    width: 100%;
    margin-right: 24px;

    h2 {
      margin-bottom: 16px;
      text-align: left;
      line-height: 1.4;
    }

    .box__desc {
      margin-bottom: 32px;
      line-height: 1.4;
      letter-spacing: 0.5px;
    }
  }

  &__item {
    position: relative;
    padding-left: 104px;

    &::before {
      @include cover-background;

      content: "";
      position: absolute;
      width: 80px;
      height: 80px;
      left: 0;
      top: 0;
    }

    &--customer::before {
      background-image: url("../images/icons/customer-icon.svg");
    }

    &--quality::before {
      background-image: url("../images/icons/quality-icon.svg");
    }

    &--innovation::before {
      background-image: url("../images/icons/innovation-icon.svg");
    }

    &:not(:last-of-type) {
      margin-bottom: 32px;
    }

    h3 {
      margin-bottom: 12px;
      padding-top: 4px;
    }

    ul {
      width: 95%;
      padding-left: 28px;
    }

    li {
      list-style: disc;

      &:not(:last-of-type) {
        margin-bottom: 20px;
      }
    }
  }

  &__img {
    border-radius: var(--radius-medium);
    object-position: left;
  }

  @include media(tablet-wide) {
    &__img {
      max-width: 40%;
    }
  }

  @include media(tablet) {
    &__item {
      padding-left: 60px;

      &:not(:last-of-type) {
        margin-bottom: 24px;
      }

      &::before {
        width: 50px;
        height: 50px;
      }

      ul {
        width: 100%;
        padding-left: 16px;
      }

      li:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }
  }

  @include media(mobile-l) {
    &__flex {
      flex-direction: column-reverse;
    }

    &__content {
      max-width: 100%;
      margin-right: 0;
    }

    &__img {
      max-width: 100%;
      width: 100%;
      height: auto;
      margin-bottom: 24px;
      aspect-ratio: 3 / 2;
      object-position: left 65%;
    }

    &__item {
      padding-left: 0;

      h3 {
        padding-left: 66px;
        padding-block: 16px;
      }
    }
  }

  @include media(mobile-sm) {
    &__item {
      &::before {
        width: 40px;
        height: 40px;
      }

      h3 {
        padding-left: 50px;
        padding-block: 12px 18px;
        font-size: 20px;
      }
    }
  }
}