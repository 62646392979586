@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.cards {
  .intro {
    line-height: 1.36;
  }

  &__desc {
    margin-bottom: 24px;
    max-width: 746px;
    margin-inline: 0;
  }

  &__grid {
    display: grid;
    grid-auto-rows: auto;
    align-items: stretch;
    gap: 24px;
  }

  &__item {
    padding: 24px;
    background-color: var(--color-gray-100);
    border-radius: var(--radius-medium);
  }

  &--three {
    .cards__grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &--two {
    .cards__grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &--odd {
    .cards__grid {
      grid-template-columns: repeat(6, auto);
    }

    .cards__item {
      grid-column: span 2;

      &--long {
        grid-column: span 3;
      }
    }
  }

  &--icons {
    .cards__item {
      padding: rem(32);
    }

    .cards__title {
      position: relative;
      padding-top: 98px;

      &::before {
        @include contain-background;

        content: "";
        position: absolute;
        top: 0;
        width: 76px;
        height: 74px;
      }

      &--network::before {
        background-image: url("../images/icons/network-icon.svg");
      }

      &--cloud::before {
        background-image: url("../images/icons/cloud-icon.svg");
      }

      &--cyber::before {
        background-image: url("../images/icons/cyber-icon.svg");
      }
    }
  }

  &--btn {
    .cards__grid {
      margin-bottom: rem(32);
    }

    .btn {
      display: block;
      width: fit-content;
      margin-inline: auto;
    }
  }

  &--reviews {
    .cards__item {
      h3 {
        margin-bottom: 20px;
        line-height: 1;
      }

      p {
        margin-bottom: 20px;
        line-height: 1.43;
      }
    }


  }

  &--avatars {
    .cards__item {
      padding: rem(32) rem(34);
    }

    .cards__inner {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      p {
        grid-column: span 2;
      }

      h3 {
        margin-bottom: 0;
        padding-top: 6px;
        line-height: 1;
      }

      span {
        font-size: 24px;
        line-height: 1.33;
        letter-spacing: 1px;
      }
    }

    .cards__avatar {
      max-width: 103px;
      width: 100%;
      margin-right: 24px;
      border-radius: var(--radius-full);
    }
  }

  &--accent {
    background-color: var(--color-purple-600);

    h2,
    .intro,
    .cards__desc {
      color: var(--color-white)
    }

  }

  &--accent-reverse {
    background-color: var(--color-orange-500);

    h2,
    .intro,
    .cards__desc {
      color: var(--color-white)
    }

    h3 {
      color: var(--color-purple-600)
    }
  }

  &--left {
    h2 {
      text-align: left;
    }
  }

  &--services {
    padding-top: 0;
  }

  @include media(tablet-wide) {
    &--avatars {
      .cards__item {
        padding: 24px;
      }

      .cards__inner {
        flex-direction: column;
        text-align: center;
      }

      .cards__avatar {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
  }

  @include media(tablet) {
    &__grid {
      gap: 16px;
    }

    &__item {
      padding: 16px;
    }

    &--three {
      .cards__grid {
        grid-template-columns: repeat(2, 1fr);
      }

      .cards__item {
        &:last-of-type {
          grid-column: span 2;
        }
      }

      .cards__title {
        padding-top: 60px;

        &::before {
          width: 50px;
          height: 50px;
        }
      }
    }

    &--icons {
      .cards__item {
        padding: 16px;
      }
    }

    &--avatars {
      .cards__item {
        padding: 16px;
      }
    }

    &--even {
      .cards__item:last-of-type {
        grid-column: auto;
      }
    }
  }

  @include media(mobile-l) {
    &__grid {
      grid-auto-rows: auto;
    }

    &--three {
      .cards__grid {
        grid-template-columns: 1fr;
      }

      .cards__item {
        &:last-of-type {
          grid-column: auto;
        }
      }
    }

    &--two {
      .cards__grid {
        grid-template-columns: 1fr;
      }
    }

    &--odd {
      .cards__grid {
        grid-template-columns: 1fr;
      }

      .cards__item {
        grid-column: auto;

        &--long {
          grid-column: auto;
        }
      }
    }

    &--avatars {
      .cards__inner {
        text-align: left;
        flex-direction: row;
      }

      .cards__avatar {
        margin-right: 16px;
        margin-bottom: 0;
      }
    }
  }

  @include media(mobile-m) {
    &__item {
      h3 {
        font-size: 20px;
        line-height: 1.3;
      }
    }

    &--services {
      padding-top: 20px;
    }
  }

  @include media(mobile-sm) {
    &--avatars {
      .cards__inner {
        text-align: center;
        flex-direction: column;
      }

      .cards__avatar {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
  }
}