@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.texts {
  h1,
  &__desc {
    max-width: 856px;
    width: 100%;
    margin-inline: 0;
    text-align: left;
  }

  h1 {
    margin-bottom: rem(32);
    color: var(--color-orange-500);
  }

  h2 {
    text-align: left;
  }

  &__desc {
    &:not(:last-of-type) {
      margin-bottom: rem(64);
    }
  }

  .cards {
    margin-bottom: rem(64);
  }

  &__content {
    width: 56%;
  }

  &__text {
    p:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &--single {
    padding-bottom: rem(80)
  }

  @include media(tablet) {
    &__content {
      width: 80%;
    }

    &--single {
      padding: rem(30) 0;

      .texts__desc {
        &:not(:last-of-type) {
          margin-bottom: 24px;
        }
      }
    }
  }

  @include media(mobile-l) {
    .cards {
      margin-bottom: 40px;
    }
  }

  @include media(mobile-m) {
    h1 {
      margin-bottom: 24px;
    }

    &__content {
      width: 100%;
    }
  }
}