@font-face {
  src: url("../futura-medium.d59cdb8e.TTF") format("woff2");
  font-family: Futura;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../futura-bold.a047f4bb.TTF") format("woff2");
  font-family: Futura;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

:root {
  --content-width: 1296px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --font-family-primary: "Futura", sans-serif;
  --fw-500: 500;
  --fw-700: 700;
  --color-white: #fff;
  --color-black: #111;
  --color-gray-100: #f6f6f6;
  --color-orange-500: #ff7528;
  --color-purple-670: #4f3489b3;
  --color-purple-650: #4f348980;
  --color-purple-600: #4f3489;
  --radius-main: 16px;
  --radius-medium: 24px;
  --radius-large: 32px;
  --radius-full: 50%;
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-500);
  color: var(--color-purple-600);
  background-color: var(--color-white);
  font-optical-sizing: auto;
  font-size: clamp(18px, 1rem + 1vi, 20px);
  font-style: normal;
  line-height: 1.4;
}

.page__body {
  min-width: 320px;
  min-height: var(--vh);
}

.page.open {
  overflow: hidden;
}

section {
  padding: 4rem 0;
}

@media only screen and (max-width: 992px) {
  section {
    padding: 1.875rem 0;
  }
}

.container {
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
}

.site-container {
  min-height: var(--vh);
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  display: grid;
}

.main {
  padding-top: calc(var(--header-height)  - 1px);
}

.overlay {
  position: relative;
}

.overlay:before {
  content: "";
  z-index: 1;
  background-color: var(--color-purple-650);
  position: absolute;
  inset: 0;
}

.hidden {
  display: none !important;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  width: 100%;
  height: 100dvh;
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

h1, h2, h3, h4, .accent {
  font-weight: var(--fw-700);
  text-transform: capitalize;
  overflow: hidden;
}

h2, h3, .accent {
  color: var(--color-orange-500);
}

h1 {
  color: var(--color-white);
  letter-spacing: .5px;
  font-size: clamp(32px, 4vw, 62px);
  line-height: 1.2;
}

h2, .title {
  text-align: center;
  margin-bottom: 24px;
  line-height: 1.1;
}

h2 {
  font-size: clamp(26px, 3.5vw, 56px);
}

h3, .accent {
  letter-spacing: .5px;
  margin-bottom: 16px;
  font-size: clamp(22px, .5rem + 1.5vi, 32px);
  line-height: 1;
}

.accent {
  margin-bottom: 24px;
}

h4 {
  letter-spacing: .5px;
  color: var(--color-orange-500);
  font-size: clamp(20px, 2vw, 32px);
  line-height: 1.3;
}

.title {
  color: var(--color-purple-600);
  letter-spacing: 1.2px;
  font-size: clamp(30px, 3vw, 48px);
}

.desc {
  font-size: clamp(22px, 1rem + 1vi, 32px);
  line-height: 1.4;
  font-weight: var(--fw-500);
  color: var(--color-white);
  letter-spacing: 1.2px;
}

a {
  font-size: clamp(18px, 1rem + 1vi, 20px);
  line-height: 1;
  font-weight: var(--fw-700);
  color: var(--color-white);
}

p {
  letter-spacing: .7px;
  line-height: 1.4;
}

.intro {
  max-width: 636px;
  width: 100%;
  text-align: center;
  margin: 0 auto 2rem;
}

textarea {
  color: var(--color-purple-600);
}

textarea::placeholder {
  color: var(--color-purple-670);
}

.copyright {
  color: var(--color-white);
  font-size: 16px;
  line-height: 1.33vw;
}

.header {
  z-index: 10;
  width: 100%;
  background-color: var(--color-purple-600);
  padding: clamp(12px, 2vi, 22.5px) 0;
  transition-property: transform, padding;
  transition-duration: .15s;
  position: fixed;
  top: 0;
}

.header.hide {
  transform: translateY(-100%);
}

.nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.nav img {
  height: auto;
}

.nav .logo {
  max-width: 127px;
  width: 100%;
  margin-right: 24px;
}

@media only screen and (max-width: 992px) {
  .nav .logo {
    max-width: 100px;
  }
}

.nav__inner {
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: clamp(20px, 10vw, 150px);
  display: flex;
}

@media only screen and (max-width: 1180px) {
  .nav__inner {
    gap: 24px;
  }
}

@media only screen and (max-width: 992px) {
  .nav__inner {
    left: 0;
    top: calc(var(--header-height) * 1);
    z-index: -1;
    width: 100%;
    height: calc(var(--vh));
    background-color: var(--color-purple-600);
    flex-direction: column;
    justify-content: flex-start;
    padding: 30px 16px;
    transition-property: transform;
    transition-duration: .4s;
    position: fixed;
    overflow-y: auto;
    transform: translateX(150%)translateY(-2px);
  }
}

.nav__list {
  max-width: 529px;
  width: 100%;
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .nav__list {
    z-index: -1;
    flex-direction: column;
    margin-right: 0;
  }
}

.nav__item:not(:last-of-type) {
  margin-right: clamp(24px, 3.5vw, 50px);
}

@media only screen and (max-width: 992px) {
  .nav__item:not(:last-of-type) {
    margin-right: 0;
  }

  .nav__item {
    margin-bottom: 30px;
  }
}

.nav__link {
  text-transform: capitalize;
  transition: color .4s ease-in-out;
}

.nav__link.true, .nav__link:hover {
  color: var(--color-orange-500);
}

.nav__btns {
  max-width: 315px;
  width: 100%;
  align-items: center;
  gap: 34px;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .nav__btns {
    flex-direction: column;
    gap: 30px;
  }
}

.nav--footer {
  margin-bottom: 2.8125rem;
}

.nav--footer .nav__list {
  max-width: 100%;
  justify-content: center;
}

.nav--footer .nav__item {
  padding-block: 18px;
}

@media only screen and (max-width: 992px) {
  .nav--footer {
    display: none;
  }
}

[class].btn {
  font-family: var(--font-family-primary);
  font-weight: var(--fw-700);
  text-align: center;
  border-radius: var(--radius-main);
  background-color: var(--color-white);
  border: none;
  margin: 0;
  padding: 18px 26px;
  font-size: clamp(18px, 1rem + 1vi, 20px);
  line-height: 1;
  transition-property: background-color, color;
  transition-duration: .4s;
  transition-timing-function: ease-in-out;
  display: inline-block;
  color: var(--color-purple-600) !important;
}

[class].btn--reverse, [class].btn:hover {
  background-color: var(--color-orange-500);
  color: var(--color-white) !important;
}

[class].btn--reverse:hover {
  background-color: var(--color-white);
  color: var(--color-purple-600) !important;
}

.burger {
  --line-height: 2px;
  --buger-size: 20px;
  --move: calc(var(--buger-size) / 3);
  --move-decrement: calc(var(--move) * -1);
  --transition-time: .5s;
  --lines-color: var(--color-white);
  width: calc(var(--buger-size)  + 5px);
  height: var(--buger-size);
  transition: opacity var(--transition-time) ease;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.burger span {
  z-index: -1;
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  transition: transform var(--transition-time) ease, background-color var(--transition-time) ease .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:before, .burger span:after {
  content: "";
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
}

.burger span:before {
  transform: translateY(var(--move-decrement)) rotate(0);
}

.burger span:after {
  transform: translateY(var(--move)) rotate(0);
}

.burger:hover {
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .burger {
    display: block;
  }
}

.closed.opened span {
  background-color: var(--lines-color);
  transition: background-color .2s;
}

.closed.opened span:before {
  animation-name: toplineburger;
}

.closed.opened span:after {
  animation-name: bottomlineburger;
}

.opened .nav__inner {
  transform: translateX(0)translateY(-2px);
}

.opened span {
  background-color: #0000;
  transition: background-color .2s;
}

.opened span:before, .opened span:after {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
}

.opened span:before {
  animation-name: toplinecross;
}

.opened span:after {
  animation-name: bottomlinecross;
}

.hero {
  padding: 0;
}

.hero__inner {
  min-height: 488px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  padding: 8.8125rem 0;
  display: flex;
  overflow: hidden;
}

.hero__content {
  z-index: 2;
  max-width: 856px;
  width: 100%;
  position: relative;
}

.hero h1 {
  margin-bottom: 16px;
}

.hero--home .hero__inner {
  min-height: 732px;
  padding: 3.8125rem 0;
}

.hero--home .desc {
  width: 90%;
  margin-bottom: 2rem;
}

.hero--services .hero__inner {
  padding-block: 4rem;
}

@media only screen and (max-width: 992px) {
  .hero__inner {
    min-height: 350px;
    padding: 60px 0;
  }

  .hero--home .hero__inner {
    min-height: 500px;
    padding: 50px 0;
  }

  .hero--services .hero__content {
    width: 80%;
  }
}

@media only screen and (max-width: 576px) {
  .hero--single .hero__inner {
    min-height: 175px;
  }
}

@media only screen and (max-width: 475px) {
  .hero--services .hero__content {
    width: 100%;
  }
}

.cards .intro {
  line-height: 1.36;
}

.cards__desc {
  max-width: 746px;
  margin-bottom: 24px;
  margin-inline: 0;
}

.cards__grid {
  grid-auto-rows: auto;
  align-items: stretch;
  gap: 24px;
  display: grid;
}

.cards__item {
  background-color: var(--color-gray-100);
  border-radius: var(--radius-medium);
  padding: 24px;
}

.cards--three .cards__grid {
  grid-template-columns: repeat(3, 1fr);
}

.cards--two .cards__grid {
  grid-template-columns: repeat(2, 1fr);
}

.cards--odd .cards__grid {
  grid-template-columns: repeat(6, auto);
}

.cards--odd .cards__item {
  grid-column: span 2;
}

.cards--odd .cards__item--long {
  grid-column: span 3;
}

.cards--icons .cards__item {
  padding: 2rem;
}

.cards--icons .cards__title {
  padding-top: 98px;
  position: relative;
}

.cards--icons .cards__title:before {
  content: "";
  width: 76px;
  height: 74px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
}

.cards--icons .cards__title--network:before {
  background-image: url("../images/icons/network-icon.svg");
}

.cards--icons .cards__title--cloud:before {
  background-image: url("../images/icons/cloud-icon.svg");
}

.cards--icons .cards__title--cyber:before {
  background-image: url("../images/icons/cyber-icon.svg");
}

.cards--btn .cards__grid {
  margin-bottom: 2rem;
}

.cards--btn .btn {
  width: -moz-fit-content;
  width: fit-content;
  margin-inline: auto;
  display: block;
}

.cards--reviews .cards__item h3 {
  margin-bottom: 20px;
  line-height: 1;
}

.cards--reviews .cards__item p {
  margin-bottom: 20px;
  line-height: 1.43;
}

.cards--avatars .cards__item {
  padding: 2rem 2.125rem;
}

.cards--avatars .cards__inner {
  align-items: center;
  margin-bottom: 24px;
  display: flex;
}

.cards--avatars .cards__inner p {
  grid-column: span 2;
}

.cards--avatars .cards__inner h3 {
  margin-bottom: 0;
  padding-top: 6px;
  line-height: 1;
}

.cards--avatars .cards__inner span {
  letter-spacing: 1px;
  font-size: 24px;
  line-height: 1.33;
}

.cards--avatars .cards__avatar {
  max-width: 103px;
  width: 100%;
  border-radius: var(--radius-full);
  margin-right: 24px;
}

.cards--accent {
  background-color: var(--color-purple-600);
}

.cards--accent h2, .cards--accent .intro, .cards--accent .cards__desc {
  color: var(--color-white);
}

.cards--accent-reverse {
  background-color: var(--color-orange-500);
}

.cards--accent-reverse h2, .cards--accent-reverse .intro, .cards--accent-reverse .cards__desc {
  color: var(--color-white);
}

.cards--accent-reverse h3 {
  color: var(--color-purple-600);
}

.cards--left h2 {
  text-align: left;
}

.cards--services {
  padding-top: 0;
}

@media only screen and (max-width: 1180px) {
  .cards--avatars .cards__item {
    padding: 24px;
  }

  .cards--avatars .cards__inner {
    text-align: center;
    flex-direction: column;
  }

  .cards--avatars .cards__avatar {
    margin-bottom: 16px;
    margin-right: 0;
  }
}

@media only screen and (max-width: 992px) {
  .cards__grid {
    gap: 16px;
  }

  .cards__item {
    padding: 16px;
  }

  .cards--three .cards__grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .cards--three .cards__item:last-of-type {
    grid-column: span 2;
  }

  .cards--three .cards__title {
    padding-top: 60px;
  }

  .cards--three .cards__title:before {
    width: 50px;
    height: 50px;
  }

  .cards--icons .cards__item, .cards--avatars .cards__item {
    padding: 16px;
  }

  .cards--even .cards__item:last-of-type {
    grid-column: auto;
  }
}

@media only screen and (max-width: 768px) {
  .cards__grid {
    grid-auto-rows: auto;
  }

  .cards--three .cards__grid {
    grid-template-columns: 1fr;
  }

  .cards--three .cards__item:last-of-type {
    grid-column: auto;
  }

  .cards--two .cards__grid, .cards--odd .cards__grid {
    grid-template-columns: 1fr;
  }

  .cards--odd .cards__item, .cards--odd .cards__item--long {
    grid-column: auto;
  }

  .cards--avatars .cards__inner {
    text-align: left;
    flex-direction: row;
  }

  .cards--avatars .cards__avatar {
    margin-bottom: 0;
    margin-right: 16px;
  }
}

@media only screen and (max-width: 576px) {
  .cards__item h3 {
    font-size: 20px;
    line-height: 1.3;
  }

  .cards--services {
    padding-top: 20px;
  }
}

@media only screen and (max-width: 374px) {
  .cards--avatars .cards__inner {
    text-align: center;
    flex-direction: column;
  }

  .cards--avatars .cards__avatar {
    margin-bottom: 16px;
    margin-right: 0;
  }
}

.choose {
  padding-bottom: 5rem;
}

.choose .intro {
  line-height: 1.36;
}

.choose__items {
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: dense;
  align-items: stretch;
  gap: 24px;
  display: grid;
}

.choose__element {
  min-height: 300px;
  height: 100%;
  border-radius: var(--radius-medium);
  align-items: flex-end;
  padding: 20px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.choose__element:last-of-type {
  min-height: 307px;
  grid-column: span 2;
}

.choose__element:last-of-type .choose__content {
  width: 49%;
}

.choose__img {
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.choose__content {
  width: 70%;
  background-color: var(--color-gray-100);
  border-radius: var(--radius-medium);
  padding: 24px;
}

.choose--vertical h2, .choose--vertical .choose__items {
  margin-bottom: 2rem;
}

.choose--vertical .choose__item--odd, .choose--vertical .choose__item--even {
  grid-row: span 2;
}

.choose--vertical .choose__item--odd h3, .choose--vertical .choose__item--even h3 {
  font-size: clamp(20px, 2vw, 32px);
}

.choose--vertical .choose__item--even {
  grid-column: 2;
}

.choose--vertical .choose__item--left {
  grid-column: 1;
}

.choose--vertical .choose__element {
  min-height: 268px;
  border-radius: var(--radius-large);
  padding-bottom: 16px;
  padding-left: 32px;
}

.choose--vertical .choose__link {
  width: 75%;
}

.choose--vertical .choose__link:before {
  content: "";
  z-index: 2;
  position: absolute;
  inset: 0;
}

.choose--vertical h3 {
  color: var(--color-white);
  font-size: clamp(20px, 1.5vw, 24px);
  line-height: 1.4;
}

.choose--vertical .btn {
  width: -moz-fit-content;
  width: fit-content;
  margin-inline: auto;
  display: block;
}

.choose--insights {
  padding-bottom: 3.125rem;
}

.choose--insights h2 {
  margin-bottom: 3.75rem;
}

@media only screen and (max-width: 992px) {
  .choose {
    padding-bottom: 1.875rem;
  }

  .choose__items {
    gap: 16px;
  }

  .choose__element {
    padding: 16px;
  }

  .choose__element:last-of-type .choose__content {
    width: 65%;
  }

  .choose__content {
    width: 85%;
    padding: 16px;
  }

  .choose__img {
    object-position: right;
  }

  .choose--vertical .choose__element {
    min-height: 200px;
    padding-left: 16px;
  }

  .choose--vertical .choose__link {
    width: 90%;
  }

  .choose--insights h2 {
    margin-bottom: 24px;
  }

  .choose--insights .choose__items {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 768px) {
  .choose__items {
    grid-template-columns: 1fr;
  }

  .choose__item:last-of-type {
    grid-column: auto;
  }

  .choose__element, .choose__element:last-of-type {
    min-height: 350px;
  }

  .choose__element:last-of-type .choose__content, .choose__content {
    width: 90%;
  }
}

.texts h1, .texts__desc {
  max-width: 856px;
  width: 100%;
  text-align: left;
  margin-inline: 0;
}

.texts h1 {
  color: var(--color-orange-500);
  margin-bottom: 2rem;
}

.texts h2 {
  text-align: left;
}

.texts__desc:not(:last-of-type), .texts .cards {
  margin-bottom: 4rem;
}

.texts__content {
  width: 56%;
}

.texts__text p:not(:last-child) {
  margin-bottom: 24px;
}

.texts--single {
  padding-bottom: 5rem;
}

@media only screen and (max-width: 992px) {
  .texts__content {
    width: 80%;
  }

  .texts--single {
    padding: 1.875rem 0;
  }

  .texts--single .texts__desc:not(:last-of-type) {
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .texts .cards {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 576px) {
  .texts h1 {
    margin-bottom: 24px;
  }

  .texts__content {
    width: 100%;
  }
}

.box__flex {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.box__content {
  max-width: 634px;
  width: 100%;
  margin-right: 24px;
}

.box__content h2 {
  text-align: left;
  margin-bottom: 16px;
  line-height: 1.4;
}

.box__content .box__desc {
  letter-spacing: .5px;
  margin-bottom: 32px;
  line-height: 1.4;
}

.box__item {
  padding-left: 104px;
  position: relative;
}

.box__item:before {
  content: "";
  width: 80px;
  height: 80px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.box__item--customer:before {
  background-image: url("../images/icons/customer-icon.svg");
}

.box__item--quality:before {
  background-image: url("../images/icons/quality-icon.svg");
}

.box__item--innovation:before {
  background-image: url("../images/icons/innovation-icon.svg");
}

.box__item:not(:last-of-type) {
  margin-bottom: 32px;
}

.box__item h3 {
  margin-bottom: 12px;
  padding-top: 4px;
}

.box__item ul {
  width: 95%;
  padding-left: 28px;
}

.box__item li {
  list-style: disc;
}

.box__item li:not(:last-of-type) {
  margin-bottom: 20px;
}

.box__img {
  border-radius: var(--radius-medium);
  object-position: left;
}

@media only screen and (max-width: 1180px) {
  .box__img {
    max-width: 40%;
  }
}

@media only screen and (max-width: 992px) {
  .box__item {
    padding-left: 60px;
  }

  .box__item:not(:last-of-type) {
    margin-bottom: 24px;
  }

  .box__item:before {
    width: 50px;
    height: 50px;
  }

  .box__item ul {
    width: 100%;
    padding-left: 16px;
  }

  .box__item li:not(:last-of-type) {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .box__flex {
    flex-direction: column-reverse;
  }

  .box__content {
    max-width: 100%;
    margin-right: 0;
  }

  .box__img {
    max-width: 100%;
    width: 100%;
    height: auto;
    aspect-ratio: 3 / 2;
    object-position: left 65%;
    margin-bottom: 24px;
  }

  .box__item {
    padding-left: 0;
  }

  .box__item h3 {
    padding-left: 66px;
    padding-block: 16px;
  }
}

@media only screen and (max-width: 374px) {
  .box__item:before {
    width: 40px;
    height: 40px;
  }

  .box__item h3 {
    padding-left: 50px;
    padding-block: 12px 18px;
    font-size: 20px;
  }
}

.services {
  padding: 4rem 0 7rem;
}

.services h2 {
  margin-bottom: 4rem;
}

.services img {
  border-radius: var(--radius-medium);
  margin-bottom: 2rem;
}

.services .intro {
  max-width: 746px;
  margin-bottom: 32px;
}

.services__grid {
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  align-items: stretch;
  gap: 24px;
  margin-bottom: 2rem;
  display: grid;
}

.services__item {
  background-color: var(--color-gray-100);
  border-radius: var(--radius-medium);
  padding: 32px;
}

.services__item h4 {
  margin-bottom: 8px;
}

.services .btn {
  width: -moz-fit-content;
  width: fit-content;
  margin-inline: auto;
  display: block;
}

.services--cyber {
  padding: 0 0 7rem;
}

.services--cloud {
  padding: 0 0 5rem;
}

@media only screen and (max-width: 992px) {
  .services {
    padding: 1.875rem 0;
  }

  .services h2, .services img, .services .intro {
    margin-bottom: 24px;
  }

  .services__grid {
    gap: 16px;
  }

  .services__item {
    padding: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .services__grid {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
  }
}

@media only screen and (max-width: 576px) {
  .services img {
    height: auto;
    aspect-ratio: 4 / 3;
  }
}

@keyframes toplinecross {
  0% {
    transform: translateY(var(--move-decrement));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottomlinecross {
  0% {
    transform: translateY(var(--move));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0)rotate(-45deg);
  }
}

@keyframes toplineburger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move-decrement)) rotate(0);
  }
}

@keyframes bottomlineburger {
  0% {
    transform: translateY(0)rotate(-45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move)) rotate(0);
  }
}

.policy {
  max-width: 746px;
  width: 100%;
}

.policy h1 {
  color: var(--color-orange-500);
  margin-bottom: 24px;
}

.policy h2 {
  color: var(--color-purple-600);
  text-align: left;
  font-size: clamp(20px, .5rem + 1.5vi, 32px);
}

.policy > :not(:last-child), .policy .policy__content:not(:last-child), .policy .policy__content > :not(:last-child) {
  margin-bottom: 32px;
}

.policy ul {
  padding-left: 32px;
}

@media only screen and (max-width: 992px) {
  .policy ul {
    padding-left: 16px;
  }
}

.policy li {
  list-style: disc;
}

.policy a {
  color: var(--color-orange-500);
  text-decoration: underline;
}

@media only screen and (max-width: 1280px) {
  .policy {
    max-width: 100%;
  }
}

.footer {
  background-color: var(--color-purple-600);
  padding-block: 3.1875rem;
}

.footer__content, .footer__form {
  flex-direction: column;
  display: flex;
}

.footer__content {
  justify-content: center;
  align-items: center;
}

.footer .logo, .footer__mail {
  margin-bottom: 24px;
}

.footer__mail {
  align-items: center;
  display: flex;
}

.footer__mail a, .footer__mail span {
  font-size: clamp(20px, 1.5vw, 24px);
  line-height: 1.5;
  font-weight: var(--fw-500);
  color: var(--color-white);
  letter-spacing: 1.4px;
}

.footer__form {
  max-width: 636px;
  width: 100%;
  gap: 16px;
  margin-bottom: 2.8125rem;
}

.footer__form textarea, .footer__form .btn {
  border-radius: var(--radius-main);
  width: 100%;
}

.footer__form textarea {
  min-height: 240px;
  resize: none;
  padding: 16px;
}

.footer__links {
  align-items: center;
  margin-bottom: 24px;
  display: none;
}

.footer__item:not(:last-of-type) {
  margin-right: 16px;
}

@media only screen and (max-width: 992px) {
  .footer {
    padding: 1.875rem 0;
  }

  .footer__form {
    margin-bottom: 40px;
  }

  .footer__links {
    display: flex;
  }
}

@media only screen and (max-width: 475px) {
  .footer__mail {
    flex-direction: column;
  }

  .footer__link {
    font-size: 18px;
    font-weight: var(--fw-500);
  }
}

/*# sourceMappingURL=main.css.map */
